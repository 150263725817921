import React from 'react';

const Info = ({ vis }) => {
  return (
    <div
      className='cS-container'
      style={
        {
          zIndex: `${(vis === 'cS-inf') ? '3' : '2'}`,
          opacity: `${(vis === 'cS-inf') ? 1 : 0}`,
          transform: `translateY(${(vis === 'cS-inf') ? '0' : '-500px'})`
        }
      }
    >
      <div className='cS-infoContent'>
        <div className='aS-contentGS cSGS'></div>
        <div className='cS-infoImg'>
          <img
            src='static/CompanySection/cS-InfoImg.png'
            alt='infoimg' />
        </div>
        <div className='cS-infoText'>
          <div className='cS-infoTextContent'>
            <div>Проектная компания «INNOVATION CENTER PROJECT» была создана в 2007 году и имеет
              государственную лицензию I категории № 15-ГСЛ №000487 на занятие видами работ (услуг) в
              сфере архитектурной, градостроительной и строительной деятельности, дорожного
              проектирования на территории Республики Казахстан.</div>
            <div>Все сотрудники ТОО «Innovation Center Project» имеют не только высшее
              архитектурно-строительное образование, но и богатый опыт в
              проектировании. Ежегодно сотрудники предприятия проходят курсы
              повышения квалификации. Наши архитекторы прошли стажировку
              в Лондоне и Токио.
            </div>
            <div>В 2010г наше предприятие получило Диплом I степени и звание
              Лауреата в республиканском конкурсе «Лучших архитектурных
              произведений  за 2007-2010гг».
            </div>
            <div>Летом 2014г наш сотрудник был награжден медалью
              «Почетный архитектор Республики Казахстан».
            </div>
            <div>Имеем множество благодарственных писем от наших заказчиков.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;