import React from 'react';
import { useEffect } from 'react';
import ServicesModalTab from './ServicesModalTab';
import Arrow from '../Arrow'


const ServicesModal = ({curTab, closeTab, service, setNextTab, setPrevTab}) => {
  
  useEffect (() => {
    window.addEventListener('keyup', keyCloseCheck)
    return () => {
      window.removeEventListener('keyup', keyCloseCheck)
    }
  }, [curTab])

  const keyCloseCheck = (e) => {
    if (e.keyCode === 27) { closeTab()}
   
  }

  const gridList = {
    1: "Arch",
    2: "Urban",    
    3: "Transport",
    4: "Transport",
    5: "Network",
    6: "Transport"
  }
  

  return ( curTab != '' &&
 
    <div className='modal'>
      <div className = 'ServicesModal'>
        <div className='sM-frame'>
          <div className='sM-frameL arr' onClick={setPrevTab}>
            <Arrow dir = 'Back' stop = '' empty = ''/>
          </div>
          <div className='sM-frameR arr' onClick={setNextTab}>
            <Arrow dir = 'Next' stop = '' empty = ''/>
          </div>
          <div className='sM-frameTitle'>
            {service.title}
          </div>
          <div className='sM-frameContent'>
            <ServicesModalTab 
              grid = {gridList[curTab]}
              boxes = {service.boxes}
            />
          </div>
          <div className='sM-frameCloseBlockIcon' onClick={closeTab}>
            <img src='/static/Icons/windowCloseIcon.svg' onClick={closeTab}/>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default ServicesModal;


/*

import ModalArch from './ModalTabs/Modal_1_Arch'
import ModalUrban from './ModalTabs/Modal_2_Urban'
import ModalNetwork from './ModalTabs/Modal_3_Network'
import ModalTransport from './ModalTabs/Modal_4_Transport'

const ModalGrid = ({boxes}) => {
  switch (curTab) {
    case '1': return <ModalArch boxes={boxes}/>
    case '2': return <ModalUrban boxes={boxes}/>
    case '3': return <ModalNetwork boxes={boxes}/>
    case '4': return <ModalTransport boxes={boxes}/>
    default: return <div></div>

  }
}

<ModalGrid boxes={service.boxes}/>

*/