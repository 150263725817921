import React from 'react';


const ContactsSwitch = ({name, desc, vis, onClick, image}) => {
  return ( 
    <div 
      className='h-contactsSwitch'
      style ={{opacity: `${(vis === 'visible') ? '1': '0.5'}`}}      
    >
      <div 
        className='h-contactsSwitchName'
        name={name}
        onClick={onClick}
      >
        {desc}
      </div>
      <div className='h-contactsSwitchIconBlock'>
        <div className='h-contactsSwitchIcon' 
          style={{backgroundImage: `url(static/Icons/${image}.svg)`}}
          name={name}
          onClick={onClick}
        >
        </div>        
      </div>
    </div>
  );
}
 
export default ContactsSwitch;