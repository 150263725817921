import React from 'react';

const EmployeeTitle = ({employee, status, jobs}) => {
  //console.log(employee.name)
  //console.log(jobs)
  return ( 
    <div 
      className='cS-employeesTitle'
      style={{opacity: `${(status.curNum == 1) ? 1: 0}`}}>
      <div className='cS-employeesName'>{employee.name}</div>
      <div className='cS-employeesJob'>{jobs[`${employee.jobid}`]}</div>
    </div> 
  )
}
 
export default EmployeeTitle;




