import React from 'react'
import HeaderContact from './HeaderContact.jsx'
import ContactsSwitch from './ContactsSwitch.jsx'
import { Fragment } from 'react'


const Header = ({townObj}) => {

  const { townSwitch, 
    townDep, 
    townCont, 
    switchTown, 
    contacts } = townObj

  return ( 
    <div className='header'>
      <div className='h-construct'>
        <div className='h-content'>
          <div className='h-logoBlock'>
            <div className='h-logo'></div>
            <div className='h-titleBlock'>
              <div className='h-title Rus'>Инновационный центр проектирования</div>
              <div className='h-title Eng'>Innovation Center Project</div>
            </div>
          </div>
          <div className='h-contactsBlock'>

              {Object.keys(townDep).map(town => {
                return (
                  <Fragment>
                  <ContactsSwitch 
                    image={townCont[town]}
                    name={town} 
                    desc={townDep[town]}
                    vis="visible"/>
                  <HeaderContact contact={contacts[townCont[town]]} />
                  </Fragment>
                )
              })}
             
              <div className='h-contactsAdditional'>
                <div className='h-contactsLicence'><a href="/static/Download/Licenses.zip" >Лицензии</a></div>
                <div className='h-contactsPresentation'><a href="/static/Download/Presentation.pdf" target="_blank">Презентация</a></div>
              </div>
            </div>

                
         
          
        </div>
      </div>
    </div>
   )
}
 
export default Header;