import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import Arrow from '../Arrow'

const ProjectPhotoBlock = ({photo, setNextPhoto, setPrevPhoto, propsLabels, props}) => {
  console.log(photo)
  return ( 
    <div className='pM-framePhotoBlock'>
      <div className='pM-framePhotoContent'>
        <div 
          className='pM-framePhoto'
        >
          <img src = {`/static/Projects/${photo}`}></img>
          <div className='pM-framePhotoL arr' onClick={setPrevPhoto}>
            <Arrow dir = 'Back' stop = 'Stop' />
          </div>
          <div className='pM-framePhotoR arr' onClick={setNextPhoto}>
            <Arrow dir = 'Next' stop = 'Stop' />
          </div>
        </div>
        <div className='pM-framePhotoProps'>
          {Object.keys(props).map(prop => {
            return(
              <div className='pM-framePhotoPropsItem'>
                <div className='pM-framePhotoPropsTitle'>
                  {propsLabels[prop]}
                </div>
                <div 
                  className='pM-framePhotoPropsValue'
                  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props[prop])}}>
                </div>
              </div>
            )
          })}
        </div>
        
      </div>
    </div> 
  )
}
 
export default ProjectPhotoBlock;