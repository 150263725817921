import React, { useEffect, useState } from 'react'
import employeesData from '../../../../scripts/employeesData.json'
import EmployeesPhoto from './EmployeesPhoto.jsx'
import EmployeeTitle from './EmployeeTitle.jsx'
import Arrow from '../../Arrow'
import EmployeeDesc from './EmployeeDesc.jsx'

const Employees = ({vis}) => {
  
  const { empData, jobs, jobGroups } = employeesData[0]
  //console.log(jobs)
  const curJG = 'all'
  const [employees, setEmployees] = useState(() => {
    const emps = []
    jobGroups[curJG].order.map(num => {
      emps.push(...Object.values(empData).filter(emp => emp.jobid === num))
    })
    return(emps)
  })

  const [arrowStatuses, setArrowStatuses] = useState({
    left: 'Stop',
    right: ''
  })
  const [empStatuses, setEmpStatuses] = useState(() => {
    const statuses = []
    employees.forEach((employee, idL, employees) => {      
      const id = idL+1
      statuses.push(
        {
          curNum: id,
          emId: `em${id}`,
          rel: (id == 1) ? 'start': (id == employees.length) ? 'end': 'middle',
          tr: (id <= 3) ? id: 3,
          curVis: (id == 1) ? 'MainOut': ((id >= 1) && (id<=3)) ? 'SubMain': 'Out'
        }
      )
    })
    return (statuses)
  })

  const FindLock = (newEmpStatuses) => {
    if (!(newEmpStatuses.find((el) => el === 'lock'))) {
      setEmpStatuses(newEmpStatuses)
      setArrowStatuses({
        left: '',
        right: ''
      })
    }
    if (!newEmpStatuses.find((status) => status.curNum < 1)) {
      setArrowStatuses({...arrowStatuses, left: 'Stop'})
    }
    if (!newEmpStatuses.find((status) => status.curNum > 1)) {
      setArrowStatuses({...arrowStatuses, right: 'Stop'})
    }
  }

  

  const MoveLeft = () => {
    const newEmpStatuses = []
    empStatuses.map((status) => {
      newEmpStatuses.push( 
        (status.curNum === 1 && status.rel === 'end') ? 'lock' :
        {
          curNum: status.curNum - 1,
          emId: status.emId,
          rel: status.rel,
          tr: (status.curNum <=0) ? status.tr:
            (status.curNum >3 )? status.tr:
            status.tr - 1,
          curVis: 
            (status.curNum <=0) ? 'Out':
            (status.curNum === 1) ? 'SubMain':
            (status.curNum === 2) ? 'MainSub':
            ((status.curNum === 3) || (status.curNum === 4)) ? 'SubOut':
            'Out'
        }
      )
    })
    FindLock(newEmpStatuses)
    //console.log(empStatuses)
  }

  const MoveRight = () => {
    const newEmpStatuses = []
    empStatuses.map((status) => {
      newEmpStatuses.push( 
        (status.curNum === 1 && status.rel === 'start') ? 'lock' :
        {
          curNum: status.curNum + 1,
          emId: status.emId,
          rel: status.rel,
          tr: (status.curNum < 0) ? status.tr:
            (status.curNum >=3 )? status.tr:
            status.tr + 1,
          curVis: (status.curNum <=-2) ? 'Out':
            (status.curNum === -1) ? 'SubOut':
            (status.curNum === 0) ? 'MainSub':
            ((status.curNum === 1) || (status.curNum === 2)) ? 'SubMain':
            'Out'
        }
      )    

    }) 
    FindLock(newEmpStatuses)
    //console.log(empStatuses)
  }


  return(
    <div 
      className = 'cS-container'
      style={
        {
          zIndex: `${(vis === 'cS-emp')? '3': '2'}`,
          opacity: `${(vis === 'cS-emp') ? 1: 0}`,
          transform: `translateY(${(vis === 'cS-emp') ? '0': '-500px'})`
        }
      }
    >
      <div className='cS-employees'>
        <div id='cSeBack' className='cS-employeesL arr' onClick={MoveRight}>
          <Arrow dir = 'Back' stop = {`Stop`} hover = {(arrowStatuses.left != '')? 'lock': 'on'}/>
        </div>
        <div id='cSeNext' className='cS-employeesR arr' onClick={MoveLeft}>
          <Arrow dir = 'Next' stop = {`Stop`} hover = {(arrowStatuses.right != '')? 'lock': 'on'}/>
        </div>
        {employees.map((employee, id) => {

          return(
            <>
            <EmployeesPhoto 
              key={`photo${id}`}
              employee={employees[id]}
              status={empStatuses[id]}

            />
            <EmployeeTitle
              key={`title${id}`}
              employee={employees[id]}
              status={empStatuses[id]}
              jobs={jobs}
            />
            <EmployeeDesc 
              curEmp={employees[id].id}
              status={empStatuses[id]}
            />
            </>
          )
        })}       
      </div>
    </div>
    
  )
}

export default Employees