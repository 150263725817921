import React, {useEffect} from 'react';


const ModalLicense = ({lDir, licList, licListUpload, curTab, closeTab, setNextTab, setPrevTab}) => {
  //console.log(curTab)
  const lic = licList[curTab]
  const licUpload = licListUpload[curTab]
  useEffect (() => {
    document.addEventListener('keyup', keyCloseCheck)
    return () => {
      document.removeEventListener('keyup', keyCloseCheck)
    }
  }, [curTab])

  const keyCloseCheck = (e) => {

    if (e.keyCode === 27) { closeTab()}
   
  }

  return ((curTab != '' ) &&
    
      <div className='modal'>
        <div className='licModal-overlay'>
          
          <div className='licModal-imgContainer'>
            <div className='licModal-L arr' onClick={setPrevTab}>
              <img src='/static/Services/ArrBack.png' alt='sBArr'/>
            </div>
            <div className='licModal-R arr' onClick={setNextTab}>
              <img src='/static/Services/ArrNext.png' alt='sNArr'/>
            </div>
            <div className='licModal-closeBlockIcon close' onClick={closeTab}>
              <img src='/static/Icons/windowCloseIcon.svg' onClick={closeTab}/>
            </div>
            <img src = {lDir + licUpload.filename} alt='lic'/>
          </div>
          <div className='licModal-description'>
            <div>{lic.name}</div>
            <div>{'(стр. ' + lic.page + ' из ' + lic.maxpage + ')'}</div>
          </div>
          
        </div>
      </div>
    
  )
}

export default ModalLicense