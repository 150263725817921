import React from 'react';

const ServicesModalTab = ({grid, boxes}) => {
  return (
    <div className={`sM-frameGrid${grid}`} >
      {Object.keys(boxes).map(bkey => {
        return(
          <div className={`sM-frameGrid${grid}Box${bkey}`}>
            <div className={`sM-frameGrid${grid}BoxTitle`}>
              {boxes[bkey].title}
            </div>
            {(boxes[bkey].content != 'none') ? 
              <div className={`sM-frameGrid${grid}BoxContent`}>
                {boxes[bkey].content.map(row => {
                  return(
                    <div className={`sM-frameGrid${grid}BoxRow`}>
                      <div className={`sM-frameGrid${grid}BoxMarkerBox`}>
                        <div className={`sM-frameGrid${grid}BoxMarker`}></div>
                      </div>
                      <div className={`sM-frameGrid${grid}BoxText`}>
                        {row}
                      </div>
                    </div>
                  )
                })}
              </div>: <></>}
          </div>
        )
      })
      
      }
    </div> 
  )
}
 
export default ServicesModalTab;