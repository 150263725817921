import React, { useState } from 'react';
import { useEffect } from 'react';
import ProjectPhotoBlock from './ProjectPhotoBlock';
import ProjectTextBlock from './ProjectTextBlock';
import projectPropsLabels from '../../../scripts/projectPropsLabels.json';
import projectsLists from '../../../scripts/projectsListData.json';
import Arrow from '../Arrow';
import ProjectsListTable from './ProjectsListTable';

const ProjectsModal = ({ curTab, closeTab, setNextTab, setPrevTab, project, projectUpload, l, duo = true }) => {
  const propsLabels = projectPropsLabels[0];
  //console.log(project, propsLabels)
  const photosList = (projectUpload && projectUpload.photos) ? projectUpload.photos.sub : [];
  const pLL = (photosList && photosList.length) || 0;
  useEffect(() => {
    document.addEventListener('keyup', keyCloseCheck);
    return () => {
      document.removeEventListener('keyup', keyCloseCheck);
    };
  }, [curTab]);
  // console.log(curTab);

  const keyCloseCheck = (e) => {

    if (e.keyCode === 27) { closeTab(); }

  };

  const [curPhoto, setCurPhoto] = useState(() => {
    let stateObj = {};
    let i = 0;
    while (i <= l) {
      console.log(i);
      stateObj = { ...stateObj, [i]: 0 };
      i++;
    }
    // console.log(stateObj)
    return stateObj;
  });

  const setNextPhoto = () => {
    setCurPhoto({ ...curPhoto, [curTab]: (curPhoto[curTab] < pLL - 1) ? curPhoto[curTab] + 1 : 0 });
  };
  const setPrevPhoto = () => {
    setCurPhoto({ ...curPhoto, curTab: (curPhoto[curTab] > 0) ? curPhoto[curTab] - 1 : pLL - 1 });
  };

  return (curTab != '' &&

    <div className='modal'>
      <div className='ProjectsModal'>
        <div className='pM-frame'>
          {
            duo &&
            <>
              <div className='pM-frameL arr' onClick={setPrevTab}>
                <Arrow dir='Back' stop='' />
              </div>
              <div className='pM-frameR arr' onClick={setNextTab}>
                <Arrow dir='Next' stop='' />
              </div>
            </>
          }

          <div className='pM-frameTitle'>
            {project.title}
          </div>
          <div className={`pM-frameContent ${(duo) ? '' : 'pM-frame-mono'}`}>
            {
              (duo) ?
                <>
                  <ProjectPhotoBlock
                    photo={photosList[curPhoto[curTab]]}
                    props={project.properties}
                    setNextPhoto={setNextPhoto}
                    setPrevPhoto={setPrevPhoto}
                    propsLabels={propsLabels}
                  />
                  <ProjectTextBlock curTab={curTab} />
                </> :
                <ProjectsListTable projectsList={projectsLists[curTab]} />
            }


          </div>
          <div className='pM-frameCloseBlockIcon' onClick={closeTab}>
            <img src='/static/Icons/windowCloseIcon.svg' onClick={closeTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsModal;