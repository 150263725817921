import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainPage from '../pages/MainPage.jsx';


const Routes = () => {

  return(

  <Switch>
    <Route exact path='/' render={() => <MainPage />}/>
  </Switch>

)}

export default Routes