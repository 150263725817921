import React from 'react';
import { useState } from 'react';
import styles from '../../../../style/variables.scss'

const EmployeesPhoto = ({status, employee}) => {

  const value = '' + employee.id + employee.title

  const pw = parseInt(styles.csPb_widthN)

  const shift = (status.tr === 0) ? -pw/6:
    (status.tr === 1) ? pw/3:
    (status.tr === 2) ? 5*pw/6:
    7*pw/6

  const scale = (['MainOut', 'MainSub'].includes(status.curVis)) ? 1: 
  (['SubOut', 'SubMain'].includes(status.curVis) )? 0.333:
  0.001

  return(
    <div 
      className={`cS-employeesPhoto${status.curVis}`}
      id={status.emId}
      style={{transform: `matrix(
       ${scale},
       0, 
       0,
       ${scale}, 
       ${shift},
       0)`,
       opacity: `${scale}`,
       backgroundImage: `url(/static/Employees/${employee.id}.png)`
      }}
    >
      {/*status.emId*/}
    </div>
    
  )
}

export default EmployeesPhoto