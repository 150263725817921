import React, { useState } from 'react'
import licListData from '../../../scripts/licensesRus.json'
import licListDataUpload from  '../../../scripts/licenses.json'
import ModalLicense from './ModalLicense.jsx'


const LicenseSection = ({modalOpen, modalClose, attributeOpen, attributeClose, backgroundType}) => {
  
  const licList = licListData[0]
  const licListUpload = licListDataUpload[0]
  //console.log(licList)
  const [curTab, setCurTab] = useState('')

  const openTab = (e) => {
    

    while (!e.target.attributes.name) {
      e.target = e.target.parentElement
    }

    modalOpen()
    setCurTab(e.target.attributes.name.value)
    document.body.setAttribute ('style', attributeOpen) 
  }

  const closeTab = () => {
    modalClose()
    setCurTab('')
    document.body.setAttribute ('style', attributeClose) 
  }

  const l = Object.keys(licList).length

  const setNextTab = () => {
    console.log(l)
    const num = parseInt(curTab)
    setCurTab(`${(num < l)? num + 1: 1}`)
  }

  const setPrevTab = () => {
    //console.log(l)
    const num = parseInt(curTab)
    setCurTab(`${(num > 1)? num - 1: l}`)
  }

  const lDir = '/static/Licenses/'

  return(
    <>
    <ModalLicense 
      lDir = {lDir}
      licList = {licList}
      licListUpload = {licListUpload}
      curTab = {curTab}
      closeTab = {closeTab}
      setNextTab = {setNextTab}
      setPrevTab = {setPrevTab}
    />
    

    <div className='licenseSection'
       style={{backgroundImage: `${(backgroundType === 'mobile') ? `url('/static/BackgroundImages/licenses.png')`: 'none'}`}}
    >
      <div className='lS-construct'>
        <div className='lS-title title'>
          Лицензии
        </div>
        <div className='lS-content'>
          {Object.values(licList).map((lic, i) => {
            return(
              <>
              <div className='lS-block'>
                <div 
                  name={lic.id}
                  className='lS-pic'
                  onClick={openTab}>
                  <img src={lDir+Object.values(licListUpload)[i].filename} alt = 'licsmall'/>
                </div>
              </div>
              </>
          )})}
        </div>
      </div>
    </div>    
  </>
  )
}

export default LicenseSection