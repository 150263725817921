import React from 'react';
import descObj from '../../../../scripts/employeesDescData.json'
import DOMPurify from 'dompurify'

const EmployeeDesc = ({curEmp, status}) => {
  const desc = descObj[curEmp]
  if (!desc) console.log(desc, curEmp)

  return ( 
    <div 
      className='cS-employeesDesc'
      style={{opacity: `${(status.curNum == 1) ? 1: 0}`}}
    >
      {desc.map(row => {
        return(
          <>
          <div className={`cS-employeesDescRow`}>
            <div className={`cS-employeesDescMarkerBox`}>  
              <div className={`cS-employeesDescMarker marker_${row.marker}`}>
              </div> 
            </div>               
              <div className={`cS-employeesDescText`}>  
                {(row.marker === 'area') ? 
                  <span
                    className='bggrey'
                  >
                    Общая площадь участка:
                  </span>: 
                  <></>
                }   
                {row.textData.map(textItem => {
                  return(            
                  <>                   
                    {(textItem.type === 'html') ? 
                      <span
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(textItem.text)}}
                        className={`tc${textItem.color}`}
                      >
                      </span> :
                      <span
                        className={`tc${textItem.color}`}
                      >
                        {textItem.text}
                      </span>
                    }                    
                  </> 
                  )
                })}
              </div>            
            </div>     
          </>        
        )
    })}      
    </div> 
  )
}
 
export default EmployeeDesc;