import React, { Fragment, useEffect, useState } from 'react';
import StartSection from '../components/mainpage/StartSection/StartSection';
import NavBar from '../components/NavBar';
import CompanySection from '../components/mainpage/CompanySection/CompanySection';
import ServicesSection from '../components/mainpage/ServicesSection/ServicesSection';
import ProjectsSection from '../components/mainpage/ProjectsSection/ProjectsSection';
import LicenseSection from '../components/mainpage/LicenseSection/LicenseSection';
import AdvantagesSection from '../components/mainpage/AdvantagesSection/AdvantagesSection';
import Footer from '../components/mainpage/Footer/Footer';
import Header from '../components/mainpage/Header/Header'
import labelSet from '../scripts/labelSet.json'
import styles from '../style/variables.scss'



const MainPage = () => {

    
  const navH = parseInt(styles.navH)
  const sH = window.innerHeight - navH
  const mwh = (window.innerWidth > 1312) ? window.innerHeight/657: 1
  const fontSize = 10
  const attributeOpen = (window.innerWidth > 1312) ? 
    'overflow-y:hidden; padding-right: 15px; box-shadow: inset -7.5px 0 0 7.5px #fff;':
    'overflow-y:hidden; '
  const attributeClose = 'overflow-y:auto; '
  const attributeFont = ``
  const backgroundType = (window.innerWidth > 1312) ? 'desktop': 'mobile'


  const [modal, setModal] = useState('closed')
  const [scrollS, setScrollS] = useState()
  const [scrollStatus, setScrollStatus] = useState(0)
  const [delta, setDelta] = useState(0)
  const [attributes, setAttributes] = useState({
    attributeOpen: {
      attr: attributeOpen,
      enable: 0
    },
    attributeClose: {
      attr: attributeClose,
      enable: 1
    },
    attributeFont: {
      attr: attributeFont,
      enable: 1
    }
  })

  const switchAttribute = (attrObjKey, enable) => {
    //console.log(attrObjKey, enable)
    if (attributes[attrObjKey]) {
      const newAttributes = {...attributes}
      newAttributes[attrObjKey].enable = enable
      setAttributes(newAttributes)
      //console.log(newAttributes)
    }
    
  }

  useEffect (() => {    
    console.log('applied')
    if (attributes) {
      document.body.setAttribute(
        'style', 
        Object.values(attributes)
          .map((attrObj) => {
            return (attrObj.enable == 1) ? attrObj.attr: ''
          })
          .reduce((a, c) => a + c)    
      )
    }
    console.log(attributes)
  }, [attributes])

  const [scrollBackground, setScrollBackground] = useState(() => {
    const scroll = localStorage.getItem('scroll')
    if (window.performance) {
      if (performance.navigation.type in [1]) {
          if (scroll != window.pageYOffset) 
          { 
            localStorage.setItem('scroll', window.pageYOffset)
          }        
      }
    }
    
    const result = (scroll) ? 
      (scroll/sH < 1) ? 'start':
      (scroll/sH < 3) ? 'services': 
      'licenses':
    'start'
    return(result)        
  })

  window.addEventListener('wheel', (e) => {e.preventDefault()})

  useEffect(() => {
    

    const updateScrollTop = () => {
        setScrollS(document.documentElement.scrollTop)
        localStorage.setItem('scroll', document.documentElement.scrollTop)       
    }
    
    window.addEventListener("scroll", () => {
      updateScrollTop()
      //console.log(document.documentElement.scrollTop, ' ', window.pageYOffset,  ' ', scrollS)

    });
    return () => {
      window.removeEventListener("scroll", () => {
        updateScrollTop()
       //console.log(document.documentElement.scrollTop, ' ', window.pageYOffset, ' ', scrollS)
  
      });
    }

  }, [document.documentElement.scrollTop])

  const modalOpen = () => {
    setModal('opened')
    switchAttribute('attributeClose', 0)
    switchAttribute('attributeOpen', 1)
  }

  const modalClose = () => {
    setModal('closed')
    switchAttribute('attributeClose', 1)
    switchAttribute('attributeOpen', 0)
  }

  // useEffect (() => {
    
  //   // window.addEventListener('wheel', timeoutScroll)
  //   // window.addEventListener('keyup', timeoutScroll)  
   
  //   return () => {
  //     // window.removeEventListener('wheel', timeoutScroll)        
  //     // window.removeEventListener('keyup', timeoutScroll)
  //   }
  
  // }, [document.documentElement.scrollTop, modal])
 
  // const timeoutScroll = (e) => {

  //   setTimeout(() => {
  //     if (modal === 'closed') {
  //       if (e.type == 'wheel') {wheelCheck(e)}
  //       if (e.type == 'keyup') {keyCheck(e)}      }
     
  //   }, 50)
  // }

  // const wheelCheck = (e) => {
  //   if (e.deltaY > 0) {
  //     document.documentElement.scrollTop = (parseInt(document.documentElement.scrollTop/sH)+1)*sH
  //     setDelta(1)
  //   }
  //   if (e.deltaY < 0) {
  //     document.documentElement.scrollTop = (parseInt(document.documentElement.scrollTop/sH))*sH
  //     setDelta(0)
  //   }
  // }

  // const keyCheck = (e) => {
  //   if (e.keyCode === 33 || e.keyCode === 38) {
  //     document.documentElement.scrollTop = (parseInt(document.documentElement.scrollTop/sH))*sH
  //     setDelta(0)
  //   }
  //   if (e.keyCode === 34 || e.keyCode === 40) {
  //     document.documentElement.scrollTop = (parseInt(document.documentElement.scrollTop/sH)+1)*sH
  //     setDelta(1)
  //   } 
  // }

  useEffect(() => {
    const scroll = localStorage.getItem('scroll')
    const curStat = parseInt((scroll)/sH)
    setScrollStatus(parseInt((scrollS)/sH))
    setScrollBackground(
      (curStat < 1) ? 'start':
      (scrollS/sH === 1) ?
        (scrollBackground === 'services') ? 'start':
        'services':
      (curStat < 3) ? 'services':
      (scrollS/sH === 3) ? 
        (scrollBackground === 'services') ? 'licenses':
        'services':
      (curStat >= 3) ? 'licenses':
      'start'
    )
  }, [scrollS])


  var contacts = {}
  labelSet.map(label => {
    contacts = {...contacts, [label.labelName]:{...label}}
  })

  const [townSwitch, setTownSwitch] = useState('smsk')

  const switchTown = (e) => {
    setTownSwitch(e.target.attributes.name.value)
  }
  
  const townObj = {
    townSwitch,
    townDep: {
      smsk: 'г. Москва, Россия',
      spvl: 'г. Павлодар, Казаxстан'
    },
    townCont: {
      smsk: 'msk',
      spvl: 'pvl'
    },
    switchTown,
    contacts
  }

 


  return(
    <Fragment>
      {
        (backgroundType === 'desktop') && 
        <div className = 'fixedOverlay'>
          <div 
            className = 'fixedBG'
            style={{backgroundImage: 
              `url(/static/BackgroundImages/${scrollBackground}.png)`}}>          
          </div>
        </div>
      }
        
      
      {<div 
        className = {`homeIcon ${(scrollStatus >= 1)? '': 'out'}`}
        onClick = {() => {document.documentElement.scrollTop = 0}}
      >
        <img
          src='/static/Icons/homeicon.svg'
        >
        </img>
      </div>}
     
      <Header townObj={townObj} scrollS={scrollS} />
      <NavBar 
        townObj={townObj} 
        scrollS={scrollS} 
        scrollStatus={scrollStatus} 
        sH={sH}
        delta={delta}
        setDelta={setDelta}/>
      <StartSection 
        backgroundType={backgroundType}/>
      <CompanySection />
      <ServicesSection 
        backgroundType = {backgroundType}
        modalOpen = {modalOpen}
        modalClose = {modalClose}/>
      <ProjectsSection 
        modalOpen = {modalOpen}
        modalClose = {modalClose}
        attributes = {attributes}
        switchAttribute = {switchAttribute}/>
      <LicenseSection 
        backgroundType = {backgroundType}
        modalOpen = {modalOpen}
        modalClose = {modalClose}
        attributes = {attributes}
        switchAttribute = {switchAttribute}/>
      <AdvantagesSection />

      <Footer 
        townObj={townObj}
        backgroundType = {backgroundType}/>
    </Fragment>
  )
}

export default MainPage