import React, { useState } from 'react';
import FooterContact from './FooterContact';

const Contacts = ({changeCurMapId, preventClicks, contacts, curMapId}) => {



  return ( 
    <div className='fcontacts'>
      {Object.values(contacts).map(contact => {
        return(
          <div 
            id = {`f${contact.labelName}`}
            name={contact.labelName}
            onTouchStart={preventClicks}
            className={`fC-contactItem`}
            onClick={changeCurMapId}
            
          >
            <FooterContact contact={contact} arrow = {''}/>
          </div>
        )        
      })}
      
    </div>
   );
}
 
export default Contacts;