import React from 'react';
import projectsData from  '../../../scripts/projectsPPDataRus.json';
import ProjectCard from './ProjectCard.jsx';
import { useState } from 'react';
import ProjectsModal from './ProjectModal.jsx';
import Arrow from '../Arrow'
import projectsDataUpload from '../../../scripts/projectsPPData.json';

const ProjectsSection = ({modalOpen, modalClose, attributeOpen, attributeClose}) => {
  const projectsObj = projectsData[0]
  const projectsObjUpload = projectsDataUpload[0]
  //console.log(projectsObj)
  const projects = Object.keys(projectsObj).filter(num => !isNaN(num))
  const cProjects = Object.keys(projectsObj).filter(num => projectsObj[num].type === 'c')
  const [curTab, setCurTab] = useState('')

  const openTab = (e) => {
    

    while (!e.target.attributes.name) {
      e.target = e.target.parentElement
    }

    modalOpen()
    setCurTab(e.target.attributes.name.value)
    document.body.setAttribute('style', attributeOpen) 
    console.log(document.body.getAttribute('style'))
  }

  const closeTab = () => {
    modalClose()
    setCurTab('')
    document.body.setAttribute('style', attributeClose) 
  }

  const l = Object.keys(cProjects).length

  const setNextTab = () => {
    //console.log(l)
    // console.log(curTab, cProjects)
    const num = cProjects.indexOf(curTab)
    const nextnum = (num < l-1)? num + 1: 0
    // console.log(num, nextnum)
    setCurTab(cProjects[nextnum])
    // console.log(curTab)

  }

  const setPrevTab = () => {
    //console.log(l)
    const num = cProjects.indexOf(curTab)
    const nextnum = (num > 0)? num - 1: l-1
    setCurTab(cProjects[nextnum])
  }

  const [arrowStatuses, setArrowStatuses] = useState({
    left: 'Stop',
    right: ''
  })

  const [cardStatuses, setCardStatuses] = useState(() => {
    const statuses = []
    projects.forEach((project, id, projects) => {
      statuses.push({
        curNum: id,
        pcId: `pc${id}`,
        rel: (id == 0) ? 'start': (id == projects.length - 1) ? 'end': 'middle',
        tr: (id <= 2) ? id: 2,
        curVis: (id > 2) ? 'Out': 'In'
      })
    })
    return(statuses)
  })

  const ViewStatuses = () => {
    //console.log(cardStatuses)
  }

  const FindLock = (newCardStatuses) => {
    if (!(newCardStatuses.find((el) => el == 'lock'))) {
      setCardStatuses(newCardStatuses)
      setArrowStatuses({
        left: '',
        right: ''
      })
    }
    if (!newCardStatuses.find((status) => status.curNum < 0)) {
      setArrowStatuses({...arrowStatuses, left: 'Stop'})
    }
    if (!newCardStatuses.find((status) => status.curNum > 2)) {
      setArrowStatuses({...arrowStatuses, right: 'Stop'})
    }
  }

  const MoveLeft = () => {
    const newCardStatuses = []
    cardStatuses.map((status) => {
      newCardStatuses.push( 
        (status.curNum === 2 && status.rel === 'end') ? 'lock' :
        {
          curNum: status.curNum - 1,
          pcId: status.pcId,
          rel: status.rel,
          tr: (status.curNum <=0) ? status.tr:
            (status.curNum >2 )? status.tr:
            status.tr - 1,
          curVis: (status.curNum <=0) ? 'Out':
          (status.curNum > 2)? ((status.curNum === 3)? 'In': 'Out'):
          ''
        }
      )
    })
    FindLock(newCardStatuses)
    //console.log(cardStatuses)
  }

  const MoveRight = () => {
    const newCardStatuses = []
    cardStatuses.map((status) => {
      newCardStatuses.push( 
        (status.curNum === 0 && status.rel === 'start') ? 'lock' :
        {
          curNum: status.curNum + 1,
          pcId: status.pcId,
          rel: status.rel,
          tr: (status.curNum <0) ? status.tr:
            (status.curNum >=2 )? status.tr:
            status.tr + 1,
          curVis: (status.curNum >=2) ? 'Out':
          (status.curNum < 0)? ((status.curNum === -1)? 'In': 'Out'):
          ''
        }
      )    

    }) 
    FindLock(newCardStatuses)
  }

  return(
    <>
    <ProjectsModal 
      curTab = {curTab}
      closeTab = {closeTab}
      setNextTab = {setNextTab}
      setPrevTab = {setPrevTab}
      project = {projectsObj[curTab]}
      projectUpload = {projectsObjUpload[curTab]}
      l = {l}
      duo = {(isNaN(curTab) ? false: true)}
    />
    

    <div className='projectsSection'>
      <div className='pS-construct'>
        <div className='pS-title title'>
          Наши проекты
        </div>
        <div className='pS-projectsBlock'>
          <div className='pS-projectsLists'>
            <div 
              className='pS-projectsList'
              name='Arch'
              onClick={openTab}>Перечень архитектурных работ</div>
            <div
              className='pS-projectsList'
              name='Road'
              onClick={openTab}>Перечень дорожных работ</div>
          </div>
          <div className='pS-projectsContent'>
            <div className='pS-projectsCardsBlock'>
              <div className={`pS-L arr ${(curTab != '')? 'disable': ''}`} onClick={MoveRight}>
                <Arrow dir = 'Back' stop = {`${arrowStatuses.left}`} hover = {(arrowStatuses.left != '')? 'lock': 'on'}/>
              </div>
              <div className={`pS-R arr ${(curTab != '')? 'disable': ''}`} onClick={MoveLeft}>
                <Arrow dir = 'Next' stop = {`${arrowStatuses.right}`} hover = {(arrowStatuses.right != '')? 'lock': 'on'}/>
              </div>
              {projects.map((project, id) => {
                return(
                  <ProjectCard 
                    key={id}
                    project = {projectsObj[project]}
                    projectUpload = {projectsObjUpload[project]} 
                    status = {cardStatuses[id]}
                    openTab = {openTab}
                    name={project}/>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
    </> 
  )
}

export default ProjectsSection;

/*

          

*/