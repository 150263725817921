import React from 'react'
import advData from '../../../scripts/advantagesData.json'

const AdvantagesSection = () => {

  const advantages = advData[0]
  const text = advData[1]
  

  return(
    <div className='advantagesSection'>
      <div className='aS-construct'>
        <div className='aS-content'>
          <div className='aS-WSTextBlock'>
            <div className='aS-WSTextInfo'>
              <p>{text.info1}</p>
              <p>{text.info2}</p>
              <p>{text.anthemtitle}</p>
              <p>{text.anthem1}</p>
              <p>{text.anthem2}</p>
              <p>{text.anthem3}</p>
            </div>
          </div>
          <div className='aS-advBlock'>
            {Object.values(advantages).map(adv => {
              return(
                <div className='aS-advItem'>
                  <div className='aS-advItemIcon'>
                    <img src={`/static/Advantages/advicons/${adv.img}`} alt='advIcon' />
                  </div>
                  <div className='aS-advItemText'>
                    {adv.text}
                  </div>
                </div>)
            })}            
          </div>

          <div className='aS-contentWS contentWS'>
          </div>
          <div className='aS-contentGS'></div>
        </div>
      </div>
    </div>
  )
}

export default AdvantagesSection