import React from 'react';

const SwitchButton = (props) => {
  
  return(
    <button 
    id={props.id}
    className={props.isActive ? 'cS-button active' : 'cS-button'}
    onClick={props.method}>{props.inText}</button>
  )
}

export default SwitchButton