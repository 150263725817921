import React, { useState, Fragment } from 'react';

import servicesData from '../../../scripts/services.json'
import ServicesModal from './ServicesModal.jsx';
const services = servicesData[0]

const ServicesSection = ({modalOpen, modalClose, backgroundType}) => {


  const [curTab, setCurTab] = useState('')



  const openTab = (e) => {
    while (!e.target.attributes.name) {
      e.target = e.target.parentElement
    }

    modalOpen()
    setCurTab(e.target.attributes.name.value)
  }

  const closeTab = () => {
    modalClose()
    setCurTab('')
  }

  const l = Object.keys(services).length

  const setNextTab = () => {
    console.log(l, services)
    const num = parseInt(curTab)
    setCurTab(`${(num < l)? num + 1: 1}`)
  }

  const setPrevTab = () => {
    console.log(l)
    const num = parseInt(curTab)
    setCurTab(`${(num > 1)? num - 1: l}`)
  }


  return(
    <>
    <ServicesModal 
      curTab = {curTab}
      closeTab = {closeTab}
      setNextTab = {setNextTab}
      setPrevTab = {setPrevTab}
      service = {services[curTab]}
    />

    <div className='servicesSection'
      style={{backgroundImage: `${(backgroundType === 'mobile') ? `url('/static/BackgroundImages/services.png')`: 'none'}`}}
    >
      <div className='sS-construct'>
        <div className='sS-title title'>
          Наши услуги
        </div>
        <div className='sS-content'>
          {Object.keys(services).map(id => {
            return(
            <div className='sS-serviceBlock'>
              <div className='sS-serviceBox'>
                <div className='sS-serviceIcon'>
                  {
                    (services[id].img !== 'none') && <img src={`/static/Services/TitleIcons/${services[id].img}`}/>
                  }                 
                </div>
                <div className='sS-serviceTitle'>{services[id].title}</div>
                <div 
                  className='sS-serviceButton'
                  name={id}
                  onClick={openTab}>
                  <img src='/static/Services/serviceTitleArrow.png' alt='sarr'/>
                </div>
              </div>
            </div>
            )
          })}
        </div>
      </div>     
            
    </div>
    </>
  )
}

export default ServicesSection;