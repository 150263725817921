import React, { useState, useEffect, Fragment } from 'react'; 


const NavBar = ({townObj, scrollS, scrollStatus, sH, delta, setDelta}) => {

  
  const [scrollStyle, setScrollStyle] = useState('nVisDown')  

  useEffect(() => {   
    const updateScrollStyle = () => {
      //console.log(scrollStyle)
      if (
          document.documentElement.scrollTop > 110 ||
          document.body.scrollTop > 110) 
        {
          setScrollStyle('');
          //console.log('-scrolled')
        } else if 
          (document.documentElement.scrollTop < 111 ||
          document.body.scrollTop < 111) 
        {
          setScrollStyle('nVisDown');
        }
      }
    
      updateScrollStyle()
           

  }, [scrollS])

  const MoveTab = (e) => {
    const tab = e.target.attributes.name.value
    const navOn = e.target.attributes.value.value
    if (parseInt(tab) > navOn) {setDelta(1)}
      else {setDelta(0)}
    document.documentElement.scrollTop = tab*sH
  }

  const navObj = {
    1: 'О Компании',
    2: 'Услуги',
    3: 'Проекты',
    4: 'Лицензии',
    5: 'Работа с нами',
    6: 'Контакты'
  }

  const navTabs = Object.keys(navObj)

  const { townSwitch, 
    townDep, 
    townCont, 
    switchTown, 
    contacts } = townObj

  return(
    <div className='navbar'>
      <div className='n-construct'>
        <div className='n-content'>
          <div className='n-tabContent'>
            {navTabs.map(tab => {
              const navOn = (scrollS%sH === 0) ? scrollStatus:
                (delta === 0)? scrollStatus: scrollStatus+1 
              //console.log(scrollStatus, tab)
              return(
                <div className='n-tab'>
                  <div
                    name = {tab}
                    value = {navOn} 
                    className={`n-tabItem${(navOn === parseInt(tab) ? 'In': '')}`}
                    onClick = {MoveTab}>
                    {navObj[tab]}
                  </div>
                </div>)            
          })}
          </div>
          <div className={`n-phoneContent ${scrollStyle}`}>
            {Object.values(contacts).map(contact => {
              return(
                <Fragment>    
                <div 
                  name='smsk'
                  className='n-smsk'
                  style ={{
                    backgroundImage: `url(static/Icons/${contact.labelName}.svg)`
                  }}           
                ></div>              
                <div className='n-phone'>{contact.labelPhonenumber}</div>
                
                </Fragment>
              )
            })}
           
          </div>          
        </div>
      </div>
    </div>
  )
} 

export default NavBar


/*
            <Link>О Компании</Link>
            <Link>Услуги</Link>
            <Link>Проекты</Link>
            <Link>О Компании</Link>

*/