import React from 'react';
import { useState } from 'react';

const ProjectCard = ({project, projectUpload, status, name, openTab}) => {

  const renderFalse = true && project.renderFalse
  const mainPhoto = projectUpload.photos && projectUpload.photos.main
  const [labelUp, setLabelUp] = useState('')

  const scale = (status.curVis === 'Out') ? 0: 1

  const setUp = () => {
    setLabelUp('Up')
  }

  const setDown = () => {
    setLabelUp('')
  }

  return( (!renderFalse) &&
    <div 
      className={`pS-projectsCardBlock${status.curVis}`}
      id={status.pcId}
      style={{transform: `translateX(calc(${status.tr}*395px))
        scaleX(${scale})
        scaleY(${scale})`,
        opacity: `${scale}`
      }}
    >
      <div className='pS-projectCard' 
        style={{backgroundImage: `url('/static/Projects/${mainPhoto}')`}} 
        onMouseOver={setUp}
        onMouseOut={setDown}>
        <div className={`pS-projectLabel${labelUp}`}>
          <div className='pS-projectLabelWrapper'>
            <div className='lTitle'>
              {project.title}
            </div>
            {[project.properties.placement, project.properties.year].map((prop) => {
              // console.log(project)
              return(
                <div className='lDescItem'>
                  <div className='lDesc'>
                    {prop}
                  </div>
                </div>)
            })}         
            </div>
            {
              (project.type === 'c') ? 
              <div 
                className='lButton'
                name={name}
                onClick={openTab}
              >
                Подробнее
              </div> :
              <></>
            }
            
          </div>
        </div>
    </div>
    
  )
}

export default ProjectCard;