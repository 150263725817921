import React from 'react';

const ProjectsListTable = ({projectsList}) => {
  console.log(projectsList)
  return ( 
    <div className='pM-frameTextTable'>
      {projectsList.map((row) => {
        return(
          <div className='pM-frameTextTableRow'>
            <div className={`pM-frameTextMarkerBox`}>  
              <div className={`pM-frameTextMarker marker_c1`}>
              </div> 
            </div>              
            <div className='pM-frameTextTableValue'>{row.title}</div>
            <div className='pM-frameTextTableDate'>{row.date}</div>
          </div>
        )
      })}
    </div>
  );
}
 
export default ProjectsListTable;