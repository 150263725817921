import React from 'react';


const HeaderContact = ({contact}) => {

  return ( 
    <div className={`h-contact ${contact.labelName}`}>
          <div className='h-phoneBlock'>
            <div className='h-phoneIcon'>
              <img alt='' src='/static/Icons/phone.png'/>
            </div>
            <div className='h-phone'>{contact.labelPhonenumber}</div>
          </div>
          <div className='h-addressBlock'>
            <div className='h-addressIcon'>
              <img alt='' src='/static/Icons/address.png'/>
            </div>
            <div className='h-address'>{contact.labelAddress}</div>
          </div>
        </div>
  )
}
 
export default HeaderContact;