import React from 'react'
import FooterIcon from './FooterIcon'
import Arrow from '../Arrow'

const FooterContact = ({contact, arrow}) => {
  return( 
    <>
      <div className='fC-contactTitle'>
        <div className='fC-contactTitleAr arr'>
          <Arrow dir = 'Next' stop = {arrow} hover = {(arrow === '') ? 'lock': 'on'}/>
        </div>
        {contact.labelTitle}
      </div>
      <div className='fC-contactContent'>
        <div className='fC-contactRow'>
          <FooterIcon imgl={'address'}/>
          {contact.labelAddress}
        </div>
        <div className='fC-contactRow'>
          <FooterIcon imgl={'phone'}/>
          {`${contact.labelPhonenumber}${contact.labelPhonenumber2 ? `, ${contact.labelPhonenumber2}` : ''}`}
        </div>
        <div className='fC-contactRow'>
          <FooterIcon imgl={'workhours'}/>
          {contact.labelWorkhours}
          <p>{contact.labelWorkhours2}</p>
        </div>
        <div className='fC-contactRow'>
          <FooterIcon imgl={'post'}/>
          {contact.labelEmail}
        </div>
        
      </div>
    </> 
  )
}
 
export default FooterContact;