import React from 'react';
import descObj from '../../../scripts/projectsDescData.json'
import DOMPurify from 'dompurify'


const ProjectTextBlock = ({curTab}) => {

  const desc = descObj[curTab]
  //console.log(curTab)

  return ( 
    <div className='pM-frameTextBlock'>
      {desc && desc.map(row => {
        return(
          <>
          <div className={`pM-frameTextRow`}>
            <div className={`pM-frameTextMarkerBox`}>  
              <div className={`pM-frameTextMarker marker_${row.marker}`}>
              </div> 
            </div>               
              <div className={`pM-frameTextText`}>  
                {(row.marker === 'area') ? 
                  <span
                    className='bggrey'
                  >
                    {row.textData[0].text}
                  </span>: 
                  <></>
                }   
                {row.textData.map((textItem, i) => {
                  return(            
                  <>                   
                    { 
                    ((row.marker != 'area') || (i != 0)) ?
                      (textItem.type === 'html') ? 
                      <span
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(textItem.text)}}
                        className={`tc${textItem.color}`}
                      >
                      </span> :
                      <span
                        className={`tc${textItem.color}`}
                      >
                        {textItem.text}
                      </span>:
                    <span></span>
                    }                    
                  </>                
  
                  )
                })}
              </div>            
            </div>     
          </>        
        )
    })}      
    </div> 
  )
}
 
export default ProjectTextBlock;