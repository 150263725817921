import React, { useState } from 'react';
import Info from './PicContent/Info';
import Employees from './PicContent/Employees';
import SwitchButton from './SwitchButton';

const CompanySection = () => {

  const buttonText = {
    'cS-inf': 'О Компании',
    'cS-emp': 'Наша команда'
  } 

  const [conVis, setConVis] = useState('cS-inf')

  const bSetConVis = (e) => {
    setConVis(e.target.id)
    console.log(e.target.id)
  }

  

  return(
    <div className='companySection'>      
      <div className='cS-WSconstruct'>
        <div className='cS-WSContentBorder'>
          <div className='cS-infoContentWS contentGreyS'></div>

        </div>
      </div>           
      
      
      <div className='cS-construct'>
        <div className='cS-title title'>
          О Компании
        </div>
        <div className='cS-content'>
          <Info vis = {conVis}/>
          <Employees vis = {conVis}/>
        </div>
        <div className='cS-buttonsBlock'>
          <div className='cS-buttons'>
            {Object.keys(buttonText).map(key => 
            <SwitchButton 
              id={key} 
              method={bSetConVis} 
              inText={buttonText[key]} 
              isActive={(key===conVis)}/>
            )}
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default CompanySection;

/* 





          <div className='cS-textBlock'>
            <CSTextContent vis={conVis}/>
          </div>
          <div className='cS-picConstruct'>
            <div className='cS-picBlock'>
              <CSPicContent vis={conVis}/>
            </div>
          </div>
          
  


  

  

*/