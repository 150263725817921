import React, { Fragment } from 'react';
import '../style/style.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'


const App = () => {
  return (
    <Router>
      <Fragment>
        <Routes />
      </Fragment>
    </Router>
  )
}

export default App