import React, { useState, useEffect } from 'react';

const Arrow = ({dir, stop, hover}) => {

  const [hoverS, setHoverS] = useState(
    (hover === 'lock') ? 'Hover': ''
  )

  useEffect (() => {
    setHoverS((hover === 'lock') ? 'Hover': '')
    //console.log({dir, stop, hover})
    }, [hover]    
  )

  const makeHover = (e) => {
    setHoverS((hover != 'off') ? 'Hover': '')
    //console.log(hover)
  }

  const makeSimple = (e) => {
    setHoverS((hover != 'lock') ? '': 'Hover')
  }

  const name = stop + dir
  return ( 
      <img
        onMouseOver={makeHover}
        onMouseOut={makeSimple}
        onerror={"this.style.display='none'"}
        src = {`/static/Arrows/${hoverS}${name}.png`}
      >

      </img> 
  )
} 
  
export default Arrow
