import React from 'react';

const FooterIcon = ({imgl}) => {
  return ( 
    <div className='fC-contactRowIconWrap'>
      <div className='fC-contactRowIconBox'>
      <img alt='i' src={`/static/Icons/Footer/${imgl}.png`}/>
    </div>
    </div>
    
  )
}
 
export default FooterIcon;