import React, { useState } from 'react';


const StartSection = ({ backgroundType }) => {



  return (
    <div className='startSection'>
      <div className='startSection-image'>
        <div
          className={`startSection-image-current`}
          style={{ backgroundImage: `${(backgroundType === 'mobile') ? `url('/static/BackgroundImages/start.png')` : 'none'}` }}
        >
        </div>
        <div className='startSection-contentBlock'>
          <div className='startSection-title t1'>ИННОВАЦИОННЫЙ ЦЕНТР ПРОЕКТИРОВАНИЯ</div>
          <div className='startSection-title t2'>Более 15 лет на рынке</div>
        </div>
      </div>

    </div>
  );
};

export default StartSection;

/*
    const [num, setNum] = useState('1')

  const values = ['1', '2', '3']

  const nextNum = () => {
    const cur = values.indexOf(num)
    setNum (values[cur+1])

    ************************

          {values.map(value => {
        const vision = (value === num) ? 'current' : 'pending'
        return(
          <div
            className={`startSection-image-${vision}`}
            style={{backgroundImage: `url('/static/${value}.png')`}}
            onClick={nextNum}>

          </div>
        )
  }
*/