import React, { useState, useEffect } from 'react';
import Contacts from './Contacts';
import YandexMap from './YandexMap';
import mapDataset from '../../../scripts/mapDataSet.json';


const Footer = ({ townObj, backgroundType }) => {

  const [curMapId, setCurMapId] = useState('msk');

  const randomSalt = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  const onLoad1 = () => {
    setMap1(<YandexMap key={randomSalt()} data={mapDataset[1]} />);
  }

  const onLoad2 = () => {
    setMap2(<YandexMap key={randomSalt()} data={mapDataset[0]} />);
  }

  const [map1, setMap1] = useState();
  const [map2, setMap2] = useState();

  useEffect(() => {
    setTimeout(() => {
      onLoad1();
    }, 500)
    setTimeout(() => {
      onLoad2();
    }, 1000)
  }, [])

  const changeCurMapId = (e) => {
    while (!e.target.id) { e.target = e.target.parentNode; }
    setCurMapId(e.target.attributes.name.value);
  };

  const preventClicks = (e) => {
    e.preventDefault();
  };

  return (
    <div className='footer'
      style={{ marginTop: `${(backgroundType === 'mobile') ? '0' : '10vh'}` }}
    >
      <div className='footer-construct'>
        <div className='footer-content'>
          <div className='footer-contactsBlock'>
            <div className='ftitle'>
              Контакты
            </div>
            <Contacts
              changeCurMapId={changeCurMapId}
              preventClicks={preventClicks}
              contacts={townObj.contacts}
              curMapId={curMapId}
            />
            <div className='fcopyright'>
              <p>© 2020, Проектная компания</p>
              <p>“INNOVATION CENTER PROJECT”-Инновационный центр проектирования</p>
              <p>Все права защищены</p>
            </div>
          </div>
          <div className='footer-mapBlock'>
            <div className='ymap-container'>
              <div id={mapDataset[0].mapId} className='ymap-current'>
                {map1}
              </div>
            </div>
            <div className='ymap-container'>
              <div id={mapDataset[1].mapId} className='ymap-current'>
                {map2}
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;