import React, { Fragment } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const YandexMap = ({ data }) => {

  const { mapDefaultState, placemarkGeometry } = { ...data };

  return (
    <Fragment key={data.id}>
      <YMaps key={data.id}>
        <Map key={data.id} width='600px' height='315px' defaultState={mapDefaultState}>
          <Placemark geometry={placemarkGeometry} />
        </Map>
      </YMaps>
    </Fragment >
  );
};

export default YandexMap;